import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";
import { useTheme } from "@mui/material/styles";

export const MobileHeader: React.FC<MobileHeaderProps> = ({ partnerName = "omaha" }) => {
  const theme = useTheme();
  let logoImage = (
    <img
      src={`${process.env.REACT_APP_ASSET_ENDPOINT}/images/park-omaha/logox20.png`}
      alt={"Park Omaha Logo"}
      style={{ height: 20, marginRight: theme.spacing(1) }}
    />
  );

  let headerText = "In partnership with Park Omaha";

  switch (partnerName) {
    case "pittsburgh":
      logoImage = (
        <img
          src={`${process.env.REACT_APP_ASSET_ENDPOINT}/images/pittsburgh/logox72`}
          alt={"Pittsburgh Logo"}
          style={{ height: 20, marginRight: theme.spacing(1) }}
        />
      );
      headerText = "In partnership with Pittsburgh";
      break;
    case "bethlehem":
      logoImage = (
        <img
          src={`${process.env.REACT_APP_ASSET_ENDPOINT}/images/bethlehem/logox88.png`}
          alt={"Bethlehem Logo"}
          style={{ height: 20, marginRight: theme.spacing(1) }}
        />
      );
      headerText = "In partnership with Bethlehem";
      break;
    default:
      break;
  }

  return (
    <Box
      sx={{
        color: theme.palette.primary.white,
        backgroundColor: "#E8E8E8",
        paddingLeft: theme.spacing(2),
        paddingTop: theme.spacing(0.75),
        paddingBottom: theme.spacing(0.75),
        right: theme.spacing(2),
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      {logoImage}
      <Typography variant="caption" color={(theme) => theme.palette.text.primary}>
        {headerText}
      </Typography>
    </Box>
  );
};

export interface MobileHeaderProps {
  partnerName?: string;
}

export default MobileHeader;
