import React, { FC, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styled, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
// TODO: change source of image
import landingImage from "@app.automotus.io/components/images/Landing.png";
import LogoWhite from "@app.automotus.io/components/images/AppLogo/LogoWhite";
import { DesktopNavBarMenu } from "@app.automotus.io/components/NavBar/NavBarMenu";
import PartnerLandingPageHowItWorks, { PartnerLandingPageHowItWorksModal } from "./PartnerLandingPageHowItWorks";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import Carousel from "react-material-ui-carousel";
import { makeStyles } from "@mui/styles";
import Link from "@mui/material/Link";
import LogoBlue from "@app.automotus.io/components/images/AppLogo/logoBlue";

const useStyles = makeStyles({
  dancingTooltip: {
    animationName: "$bouncing",
    animationDuration: "1000ms",
    animationIterationCount: "infinite",
  },
  "@keyframes bouncing": {
    "0%": {
      right: "12px",
    },
    "50%": {
      right: "22px",
    },
    "100%": {
      right: "12px",
    },
  },
});

export const PreLaunchOnboardingPageLayout: React.FC<{ location?: string; logoSrc?: string }> = ({
  location = "",
  logoSrc = "",
}) => {
  const theme = useTheme();
  const classes = useStyles();

  const isTiny = useMediaQuery(theme.breakpoints.down("tiny"));
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isWide = useMediaQuery(theme.breakpoints.up("lg"));

  const pabblyFormid =
    location === "Fort Lee"
      ? isWide
        ? process.env.REACT_APP_PABBLY_FORM_ID_FORTLEE_DESKTOP
        : isTiny
        ? process.env.REACT_APP_PABBLY_FORM_ID_FORTLEE_TINY
        : process.env.REACT_APP_PABBLY_FORM_ID_FORTLEE_SMALL
      : "";

  const [howItWorksModalOpen, setHowItWorksModalOpen] = useState(false);

  const handleOpenHowItWorksModal = () => {
    setHowItWorksModalOpen(true);
  };

  const handleCloseHowItWorksModal = () => {
    setHowItWorksModalOpen(false);
  };

  const NAV_LINKS = [
    {
      title: "How it Works",
      to: "/",
      onClick: handleOpenHowItWorksModal,
    },
  ];

  const defaultCarousel = (
    <Box sx={{ height: "100%", background: theme.palette.primary.main }}>
      <Box
        sx={{
          position: "absolute",
          left: 0,
          top: 0,
          width: "100%",
          height: "100%",
          background: `url(${landingImage})`,
          backgroundPosition: isMobile ? "initial" : "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          mixBlendMode: "hard-light",
          opacity: 0.3,
        }}
      />
      <Box
        sx={{
          position: "absolute",
          left: { xs: theme.spacing(2), tiny: theme.spacing(3), md: theme.spacing(5) },
          top: isMobile ? theme.spacing(4) : theme.spacing(4),
          right: isMobile ? theme.spacing(2) : theme.spacing(5),
          display: "flex",
          alignItems: "center",
          width: isMobile ? 126 : 175,
          height: isMobile ? 36 : 50,
        }}
      >
        <LogoWhite height={isMobile ? 36 : 50} width={isMobile ? 126 : 175} />
      </Box>
      <Box
        sx={{
          position: "absolute",
          color: theme.palette.primary.white,
          left: { xs: theme.spacing(2), tiny: theme.spacing(3), md: theme.spacing(5) },
          right: isMobile ? theme.spacing(2) : theme.spacing(5),
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: isMobile ? "flex-end" : "center",
        }}
      >
        <Box>
          <Typography
            variant="h2"
            fontSize={{ xs: "28px", tiny: "34px", md: "60px" }}
            fontWeight={{ xs: 500, md: 400 }}
          >
            Pull Up,
          </Typography>
          <Typography
            variant="h2"
            fontSize={{ xs: "28px", tiny: "34px", md: "60px" }}
            fontWeight={{ xs: 500, md: 400 }}
          >
            Get Things Done,
          </Typography>
          <Typography
            variant="h2"
            fontSize={{ xs: "28px", tiny: "34px", md: "60px" }}
            fontWeight={{ xs: 500, md: 400 }}
          >
            Leave
          </Typography>
        </Box>
        <Typography
          variant="subtitle1"
          fontSize={{ xs: "16px", md: "24px" }}
          sx={{ mb: isMobile ? theme.spacing(2) : 0, lineHeight: "normal" }}
        >
          Our cameras auto charge your account only for the time you use
        </Typography>
      </Box>
      {!isWide && !isMobile && (
        <Box sx={{ mr: 5, display: "flex", justifyContent: "flex-end", zIndex: 100 }}>
          <Link
            id="how-it-works"
            variant="h7"
            underline={"hover"}
            component={"button"}
            onClick={handleOpenHowItWorksModal}
            sx={{ color: theme.palette.primary.white, ml: 2.5, py: 1.25, px: 2.5 }}
            fontWeight={600}
          >
            How it Works
          </Link>
        </Box>
      )}
    </Box>
  );

  const howItWorksCarousel = (
    <Box
      key="how-it-works-carousel"
      sx={{
        position: "relative",
        height: "100%",
        color: theme.palette.primary.black,
      }}
    >
      <Box sx={{ position: "absolute" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            height: isMobile ? 36 : 50,
            px: theme.spacing(2),
            py: theme.spacing(1.25),
            borderWidth: 0,
            borderBottomWidth: 1,
            borderStyle: "solid",
            borderColor: theme.palette.divider,
          }}
        >
          <LogoBlue height={isMobile ? 36 : 50} width={isMobile ? 126 : 175} />
        </Box>
        <PartnerLandingPageHowItWorks isPreLaunch />
      </Box>
    </Box>
  );

  const [carouselIndex, setCarouselIndex] = useState<number>(0);

  const onChangeCarousel = (index?: number) => {
    if (index !== undefined) {
      setCarouselIndex(index);
    }
  };

  const carouselChildren = isMobile ? [defaultCarousel, howItWorksCarousel] : [defaultCarousel];

  const [visibleDancingToolTip, setVisibleDancingToolTip] = useState<boolean | undefined>(undefined);
  const [showingTimeoutID, setShowingTimeoutID] = useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const timeoutID = setTimeout(() => {
      setVisibleDancingToolTip(true);
    }, 1000);

    setShowingTimeoutID(timeoutID);

    setTimeout(() => {
      setVisibleDancingToolTip(false);
    }, 4000);
  }, []);

  useEffect(() => {
    if (carouselIndex === 1) {
      setVisibleDancingToolTip(false);

      if (showingTimeoutID) {
        clearTimeout(showingTimeoutID);
        setShowingTimeoutID(null);
      }
    }
  }, [carouselIndex, showingTimeoutID]);

  return (
    <Box
      sx={{
        height: "100%",
        minHeight: "600px",
        position: "relative",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: isWide ? "row" : "column", height: "100%" }}>
        {!isWide && (
          <Box
            sx={{
              color: theme.palette.primary.white,
              backgroundColor: "#E8E8E8",
              paddingLeft: theme.spacing(2),
              paddingTop: theme.spacing(0.75),
              paddingBottom: theme.spacing(0.75),
              right: theme.spacing(2),
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <img
              src={logoSrc}
              alt={`${location} Logo`}
              style={{
                width: 20,
                height: 20,
                borderRadius: "50%",
              }}
            />
            <Typography
              variant="caption"
              color={theme.palette.text.primary}
              sx={{ ml: { xs: theme.spacing(0.5), tiny: theme.spacing(1) } }}
            >
              In partnership with {location} Parking Authority
            </Typography>
          </Box>
        )}

        <Box
          sx={{
            width: isWide ? "50%" : "100%",
            position: "relative",
            maxWidth: isWide ? "720px" : "100%",
            minHeight: isMobile ? "550px" : undefined,
            flex: 1,
          }}
        >
          {isMobile && carouselIndex === 0 && visibleDancingToolTip && (
            <Box
              className={classes.dancingTooltip}
              sx={{
                position: "absolute",
                backgroundColor: (theme) => theme.palette.text.primary,
                color: theme.palette.primary.white,
                borderRadius: 1,
                zIndex: 10,
                px: 1,
                py: 0.5,
                display: "flex",
                alignItems: "center",
                top: 0,
                bottom: 0,
                height: "fit-content",
                right: "9px",
                margin: "auto",
                "&::after": {
                  content: '" "',
                  position: "absolute",
                  left: "100%",
                  borderWidth: "6px",
                  borderStyle: "solid",
                  borderColor: (theme) => `transparent transparent transparent ${theme.palette.text.primary}`,
                },
              }}
            >
              <Typography variant="tooltip">Swipe to read 'How it Works'</Typography>
            </Box>
          )}
          <Carousel
            duration={300}
            animation="slide"
            autoPlay={false}
            swipe={true}
            PrevIcon={null}
            NextIcon={null}
            indicators={carouselChildren.length > 1}
            navButtonsProps={{
              style: {
                display: "none",
              },
            }}
            sx={{
              height: "100%",
            }}
            height="100%"
            cycleNavigation={false}
            indicatorIconButtonProps={{
              style: {
                color: carouselIndex === 0 ? theme.palette.primary.white : theme.palette.primary.main,
                opacity: 0.5,
              },
            }}
            activeIndicatorIconButtonProps={{
              style: {
                color: carouselIndex === 0 ? theme.palette.primary.white : theme.palette.primary.main,
                opacity: 1,
              },
            }}
            indicatorContainerProps={{
              style: {
                position: "absolute",
                bottom: 30,
                zIndex: 100,
              },
            }}
            onChange={onChangeCarousel}
          >
            {carouselChildren}
          </Carousel>
        </Box>
        {isWide && (
          <Box
            sx={{
              position: "absolute",
              left: isMobile ? theme.spacing(2) : theme.spacing(5),
              right: isMobile ? theme.spacing(2) : theme.spacing(5),
              bottom: !isWide ? theme.spacing(5) : theme.spacing(2.5),
              display: "flex",
              alignItems: "center",
              color: theme.palette.primary.white,
              zIndex: 10,
            }}
          >
            <img src={logoSrc} style={{ height: 44, width: 44 }} />
            <Typography variant="h7" sx={{ ml: 1.5 }} fontWeight={500}>
              {location} Smart Loading Zone Program
            </Typography>
          </Box>
        )}
        <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
          <Box
            sx={{
              py: theme.spacing(2.25),
              px: theme.spacing(5),
              display: isWide ? "flex" : "none",
              justifyContent: "flex-end",
            }}
          >
            <DesktopNavBarMenu targets={NAV_LINKS} />
          </Box>
          <Box
            sx={{
              flex: 1,
              position: "relative",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              "& :first-of-type": {
                display: "flex",
                justifyContent: "center",
              },
              minHeight: 300,
            }}
          >
            {!!pabblyFormid && <PabblyFormFrame pabblyFormid={pabblyFormid} />}
          </Box>
          <PartnerLandingPageHowItWorksModal
            open={howItWorksModalOpen}
            onClose={handleCloseHowItWorksModal}
            isPreLaunch
          />
        </Box>
      </Box>
    </Box>
  );
};

const PabblyForm = styled("iframe")({
  width: "100%",
  height: "100%",
  border: "none",
  display: "flex",
  justifyContent: "center",
});

const PabblyFormFrame: FC<{ pabblyFormid: string }> = ({ pabblyFormid }) => {
  const [formLoaded, setFormLoaded] = useState(false);

  return (
    <>
      {!formLoaded ? (
        <Stack alignItems={"center"} m={2}>
          <CircularProgress />
        </Stack>
      ) : null}
      <PabblyForm
        src={`https://forms.pabbly.com/form/share/${pabblyFormid}`}
        onLoad={() => setFormLoaded(true)}
        allowTransparency
        name="pabbly_form"
        id="pabbly_form"
        allowFullScreen
      />
    </>
  );
};
